import type { RouteLocationNormalized } from '#vue-router';

// Custom middleware with parts of the original auth-redirect.ts from the supabase module
// Check: https://github.com/nuxt-modules/supabase/blob/main/src/runtime/plugins/auth-redirect.ts

// The user is already logged in but the defined routes have a higher priority than the signup completion
const PRIORITY_ROUTES = ['/auth/reset-password'];

const PUBLIC_ROUTES = ['/termsOfService'];

function abortIfAuthOrPublicRoute(to: RouteLocationNormalized) {
  const config = useRuntimeConfig().public.supabase;
  const { login, callback, exclude } = config.redirectOptions;

  const EXCLUDE_ROUTES = [...(exclude ?? []), ...PUBLIC_ROUTES, ...PRIORITY_ROUTES];

  return [...EXCLUDE_ROUTES, login, callback].some(path => {
    const regex = new RegExp(`^${path.replace(/\*/g, '.*')}$`);
    return regex.test(to.path);
  });
}

export default defineNuxtRouteMiddleware(async to => {
  if (abortIfAuthOrPublicRoute(to)) return;

  const user = useSupabaseUser();
  if (!user.value) {
    return navigateTo({ name: 'login', query: { redirect_back: to.fullPath } });
  }

  const userStore = useUserStore();
  const completeSignupRouteName = 'complete-signup';
  if (!userStore.isFullyRegistered) {
    await userStore.fetchUserInformation();
  }

  if (!userStore.isFullyRegistered && to.name !== completeSignupRouteName)
    return navigateTo({ name: completeSignupRouteName });
  if (!userStore.isFullyRegistered && to.name === completeSignupRouteName) return;

  const workspace = await useWorkspace();
  const firstWorkspaceRouteName = 'first-workspace';

  if (!workspace && to.name !== firstWorkspaceRouteName) return navigateTo({ name: firstWorkspaceRouteName });
  if (!workspace && to.name === firstWorkspaceRouteName) return;

  if ([completeSignupRouteName, firstWorkspaceRouteName].includes(to.name?.toString() ?? ''))
    return navigateTo({ name: 'dashboard' });
});
