import {
  buildHexColor,
  getHexColorChannels,
  getBlendedGradientColor,
  isLuminanceTextThresholdExceeded,
} from '@bitburst-gmbh/bitburst-colors';

function getTailwindColorShades(baseColor: string): Record<number, string> {
  const adjustLuminance = (baseColor: string, factor: number): string => {
    const channels = getHexColorChannels(baseColor);
    if (!channels) return baseColor;
    const { red, green, blue } = channels;
    if (red === undefined || green === undefined || blue === undefined) return baseColor;

    // adjust color by factor to match styleguide values
    const adjustedRGB = [red, green, blue].map(color => {
      const linear = color / 255;
      return Math.round((factor < 1 ? linear * factor : linear + (1 - linear) * (factor - 1)) * 255);
    });

    return buildHexColor(adjustedRGB[0], adjustedRGB[1], adjustedRGB[2]);
  };

  const factors = [
    { key: 900, value: 0.2 },
    { key: 800, value: 0.4 },
    { key: 700, value: 0.6 },
    { key: 600, value: 0.8 },
    { key: 500, value: 1 },
    { key: 400, value: 1.2 },
    { key: 300, value: 1.4 },
    { key: 200, value: 1.6 },
    { key: 100, value: 1.8 },
    { key: 50, value: 1.9 },
    { key: 10, value: 1.96 },
  ];

  return factors.reduce(
    (result, factor) => ({ ...result, [factor.key]: adjustLuminance(baseColor, factor.value) }),
    {}
  );
}

function getContrastColors(colorShades: Record<number, string>): Record<number, string> {
  return Object.entries(colorShades).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: isLuminanceTextThresholdExceeded(value) ? '#111B1F' : '#FFFFFF',
    }),
    {}
  );
}

export function getCssVariableString(colorName: string, value: string) {
  const shades = getTailwindColorShades(getBlendedGradientColor(value) ?? '');
  const contrasts = getContrastColors(shades);
  const cssVariables: Array<string> = [];

  Object.entries(shades).forEach(([key, color]) => {
    cssVariables.push(`--dashboard-${colorName}-${key}: ${color};`);
  });

  Object.entries(contrasts).forEach(([key, color]) => {
    cssVariables.push(`--dashboard-${colorName}-contrast-${key}: ${color};`);
  });

  return cssVariables.join('');
}
