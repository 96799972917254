import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: sentry.release,

    integrations: [
      Sentry.browserTracingIntegration({
        router: router,
      }),

      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.2,
    replaysOnErrorSampleRate: 1.0,
  });
});
