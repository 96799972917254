import { createMongoAbility } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';

export default defineNuxtPlugin(async nuxtApp => {
  const userStore = useUserStore();

  const { data } = await useFetch('/api/ability', { headers: useRequestHeaders(['cookie']) });

  userStore.rolesWithAbilities = data.value?.data.userRolesWithAbilities ?? [];

  nuxtApp.vueApp.use(abilitiesPlugin, createMongoAbility(data.value?.data.rules ?? []), {
    useGlobalProperties: true,
  });
});
