export const useDashboardCustomization = defineStore('dashboardCustomization', () => {
  const companyName = ref('');
  const largeLogoImageUrl = ref('');
  const loginSidebarImageUrl = ref('');
  const navigationLogoUrl = ref('');
  const favIconUrl16x16 = ref('');
  const favIconUrl32x32 = ref('');
  const ogLogoUrl = ref('');
  const ogLogoWithTextUrl = ref('');
  const verifyEmailImageUrl = ref('');

  const privacyPolicyUrl = ref('');

  const navigationColor = ref('');
  const primaryColor = ref('');

  const zendeskBrandId = ref<null | number>(null);
  const zendeskCustomFields = ref<null | Array<{ id: number; value: string }>>([]);

  const fetchData = async () => {
    try {
      const { data: response, error } = await useFetch('/api/customization');
      if (error.value) throw error.value;
      if (!response.value?.data) return;

      companyName.value = response.value.data.companyName;
      largeLogoImageUrl.value = response.value.data.largeLogoImageUrl;
      loginSidebarImageUrl.value = response.value.data.loginSidebarImageUrl;
      navigationLogoUrl.value = response.value.data.navigationLogoUrl;
      navigationColor.value = response.value.data.navigationColor;
      primaryColor.value = response.value.data.primaryColor;
      favIconUrl16x16.value = response.value.data.favIconUrl16x16;
      favIconUrl32x32.value = response.value.data.favIconUrl32x32;
      ogLogoUrl.value = response.value.data.ogLogoUrl;
      ogLogoWithTextUrl.value = response.value.data.ogLogoWithTextUrl;
      verifyEmailImageUrl.value = response.value.data.verifyEmailImageUrl;
      privacyPolicyUrl.value = response.value.data.privacyPolicyUrl;
      zendeskBrandId.value = response.value.data.zendeskBrandId;
      zendeskCustomFields.value = response.value.data.zendeskCustomFields;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    companyName,
    largeLogoImageUrl,
    loginSidebarImageUrl,
    navigationLogoUrl,
    navigationColor,
    primaryColor,
    favIconUrl16x16,
    favIconUrl32x32,
    ogLogoUrl,
    ogLogoWithTextUrl,
    verifyEmailImageUrl,
    privacyPolicyUrl,
    zendeskBrandId,
    zendeskCustomFields,
    fetchData,
  };
});
