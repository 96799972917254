import type { WorkspaceDetails } from '../types/workspace';

export const useWorkspace = async (workspaceUuid: undefined | string = undefined) => {
  const headers = useRequestHeaders(['cookie']) as Record<string, string>;
  let workspace: undefined | WorkspaceDetails;
  if (!workspaceUuid) {
    const { data, error } = await useFetch(`/api/workspaces`, {
      headers,
    });

    if (error.value) throw createError({ statusCode: error.value.status, message: error.value.message });

    if (!data.value?.data.workspaces.length) return;

    workspace = data.value.data.workspaces[0]?.details;
  } else {
    const { data, error } = await useFetch(`/api/workspaces/${workspaceUuid}`, {
      headers,
    });

    if (error.value) throw createError({ statusCode: error.value.status, message: error.value.message });

    workspace = data.value?.data.details;
  }

  return workspace;
};

export const useSelectedWorkspace = async () => {
  const user = useSupabaseUser();
  if (!user.value) return ref(null);
  const selectedWorkspace = useCookie<null | WorkspaceDetails>(`selected-workspace-${user.value.id}`);

  selectedWorkspace.value = selectedWorkspace.value ?? (await useWorkspace()) ?? null;

  return selectedWorkspace;
};

export const useWorkspaceVerificationStatus = (verifiedAt?: null | string, rejectedAt?: null | string) => {
  const { t } = useI18n();
  if (verifiedAt) return t('publisher.verificationStatus.verified');
  if (rejectedAt) return t('publisher.verificationStatus.rejected');
  return t('publisher.verificationStatus.notVerified');
};
