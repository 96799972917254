<template>
  <nuxt-layout name="notification">
    <template #actions>
      <bb-button size="sm" custom="primary" @click="handleError">{{ $t('error.tryCompanyButton') }}</bb-button>
    </template>

    <div class="flex w-full grow flex-col items-center justify-between bg-white dark:bg-black">
      <div class="flex grow flex-col items-center justify-center py-24 text-center">
        <img src="~/assets/svg/bitlabs-error.svg" :alt="$t('error.imageAltText')" />

        <h1 class="text-28 mt-24 font-semibold text-gray-800 dark:text-gray-50" v-text="error.statusCode" />

        <div class="dark:text-gray-4200 text-13 mt-8 text-gray-600" v-text="error.statusMessage" />

        <bb-button variant="outlined" class="mt-24" @click="handleError">{{ $t('error.backButton') }}</bb-button>
      </div>

      <div class="flex w-full justify-center bg-gray-50 px-32 py-24 text-center dark:bg-gray-800">
        <div v-t="'error.companyInfoText'" class="w-full lg:w-1/2" />
      </div>
    </div>
  </nuxt-layout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';

defineProps<{ error: NuxtError }>();

definePageMeta({
  layout: false,
  name: 'error',
});

const handleError = () => clearError({ redirect: '/' });
</script>
