import payload_plugin_KP5oCl6qgs from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.34.4/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_JGbBJOi1AI from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_FHYDyFQRIG from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fb8CPhm1GW from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_AC0y6M9SvN from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_2hAXUPZO8T from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HccjPj7KWD from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_AmjPa7VDWN from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zkCGNWdSxC from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OucGh6I1mJ from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.34.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_IGoIQfEPdR from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@planetscale+database@1.19.0_@types+node@18.19.0_aws4fetch@_oyjvuuy3d245t22appd626sbhq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_WBFDQBrzmI from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@8.54.0_magicast@0.3.5_rollup@4.34.4_typesc_ovrcmyaq3243425nt44tt43cze/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_eFI83YuCQc from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@8.54.0_magicast@0.3.5_rollup@4.34.4_typesc_ovrcmyaq3243425nt44tt43cze/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_yKK7hSmSlN from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@8.54.0_magicast@0.3.5_rollup@4.34.4_typesc_ovrcmyaq3243425nt44tt43cze/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_client_PLVw9anhxe from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.34.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/.nuxt/floating-vue.mjs";
import casl_QTBkbB6FCs from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/plugins/casl.ts";
import font_awesome_4Kz3WSwqBj from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/plugins/font-awesome.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/bb-frontend-product/bb-frontend-product/packages/bitlabs-dashboard/plugins/sentry.client.ts";
import ssg_detect_2vWe1ukCoo from "/home/runner/work/bb-frontend-product/bb-frontend-product/node_modules/.pnpm/@nuxtjs+i18n@9.1.5_@vue+compiler-dom@3.5.13_eslint@8.54.0_magicast@0.3.5_rollup@4.34.4_typesc_ovrcmyaq3243425nt44tt43cze/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_KP5oCl6qgs,
  revive_payload_client_JGbBJOi1AI,
  unhead_FHYDyFQRIG,
  router_fb8CPhm1GW,
  supabase_client_AC0y6M9SvN,
  payload_client_2hAXUPZO8T,
  navigation_repaint_client_HccjPj7KWD,
  check_outdated_build_client_AmjPa7VDWN,
  chunk_reload_client_zkCGNWdSxC,
  plugin_vue3_OucGh6I1mJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IGoIQfEPdR,
  switch_locale_path_ssr_WBFDQBrzmI,
  route_locale_detect_eFI83YuCQc,
  i18n_yKK7hSmSlN,
  plugin_client_PLVw9anhxe,
  floating_vue_EIcJ7xiw0h,
  casl_QTBkbB6FCs,
  font_awesome_4Kz3WSwqBj,
  sentry_client_shVUlIjFLk,
  ssg_detect_2vWe1ukCoo
]