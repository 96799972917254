<template>
  <nuxt-loading-indicator :color="dashboardCustomizationStore.primaryColor" :throttle="50" />
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
</template>

<script setup lang="ts">
const { t, locale, mergeLocaleMessage } = useI18n();
const dashboardCustomizationStore = useDashboardCustomization();
await dashboardCustomizationStore.fetchData();
const {
  navigationColor,
  primaryColor,
  favIconUrl16x16,
  favIconUrl32x32,
  ogLogoUrl,
  ogLogoWithTextUrl,
  companyName: company_name,
} = storeToRefs(dashboardCustomizationStore);

mergeLocaleMessage(locale.value, { generic: { company_name: company_name.value } });

const bodyStyle = computed(() => {
  const nav = getCssVariableString('navigation', navigationColor.value);
  const primary = getCssVariableString('primary', primaryColor.value);
  return nav + primary;
});

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  titleTemplate(title) {
    const dashboardText = `${t('generic.company_name')} Dashboard`;
    return title ? `${title} - ${dashboardText}` : dashboardText;
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: favIconUrl32x32.value,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: favIconUrl16x16.value,
    },
  ],
});

useHead({
  bodyAttrs: {
    style: bodyStyle,
    class: 'bg-gray-50 dark:bg-gray-800',
  },
});

useSeoMeta({
  description: `Explore ${t(
    'generic.company_name'
  )}' Offer Wall Management Dashboard for flexible and profitable offer wall creation. Easily customize and optimize it to boost revenue and engagement. Take charge of your monetization strategy now!`,
  ogTitle: `${t('generic.company_name')} Dashboard`,
  ogDescription: `Explore ${t(
    'generic.company_name'
  )}' Offer Wall Management Dashboard for flexible and profitable offer wall creation. Easily customize and optimize it to boost revenue and engagement. Take charge of your monetization strategy now!`,
  ogImage: ogLogoWithTextUrl.value,
  ogUrl: 'https://dashboard.bitlabs.ai',

  twitterTitle: `${t('generic.company_name')} Dashboard`,
  twitterDescription: `Explore ${t(
    'generic.company_name'
  )}' Offer Wall Management Dashboard for flexible and profitable offer wall creation. Easily customize and optimize it to boost revenue and engagement. Take charge of your monetization strategy now!`,
  twitterImage: ogLogoUrl.value,
  twitterCard: 'summary',

  charset: 'utf-8',
  viewport: 'width=device-width, initial-scale=1.0',
  themeColor: primaryColor.value,
  msapplicationTileColor: primaryColor.value,
});
</script>
