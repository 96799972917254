import { RoleWithAbilities } from '../types/role';

export const useUserStore = defineStore('user', () => {
  const firstName = ref('');
  const lastName = ref('');
  const addressLine1 = ref('');
  const addressLine2 = ref('');
  const country = ref('');
  const region = ref('');
  const city = ref('');
  const postalCode = ref('');
  const profilePicture = ref('');
  const fullName = computed(() => `${firstName.value} ${lastName.value}`.trim());
  const isFullyRegistered = computed(() => !!firstName.value && !!lastName.value);

  const email = ref('');
  const preferredContactMethod = ref('');
  const preferredContactInfo = ref('');
  const createdAt: Ref<null | Date> = ref(null);
  const updatedAt: Ref<null | Date> = ref(null);

  const rolesWithAbilities = ref<Array<RoleWithAbilities>>([]);

  const roles = computed(() =>
    rolesWithAbilities.value.map(role => ({
      name: role.role.name,
      badgeColor: role.role.badgeColor,
      badgePriority: role.role.badgePriority,
    }))
  );

  async function fetchUserInformation() {
    const headers = useRequestHeaders(['cookie']) as Record<string, string>;
    const { data } = await useFetch('/api/user', { headers });

    if (data.value?.status === 'success') {
      if (data.value.data.user.firstName) firstName.value = data.value.data.user.firstName;
      if (data.value.data.user.lastName) lastName.value = data.value.data.user.lastName;
      if (data.value.data.user.email) email.value = data.value.data.user.email;
      if (data.value.data.user.createdAt) createdAt.value = new Date(data.value.data.user.createdAt);
      if (data.value.data.user.updatedAt) updatedAt.value = new Date(data.value.data.user.updatedAt);
      if (data.value.data.user.addressLine1) addressLine1.value = data.value.data.user.addressLine1;
      if (data.value.data.user.addressLine2) addressLine2.value = data.value.data.user.addressLine2;
      if (data.value.data.user.country) country.value = data.value.data.user.country;
      if (data.value.data.user.region) region.value = data.value.data.user.region;
      if (data.value.data.user.city) city.value = data.value.data.user.city;
      if (data.value.data.user.postalCode) postalCode.value = data.value.data.user.postalCode;
      if (data.value.data.user.profilePicture) profilePicture.value = data.value.data.user.profilePicture;

      preferredContactMethod.value = data.value.data.user.contactMethod ?? 'email';
      preferredContactInfo.value = data.value.data.user.contactInfo ?? email.value;
    }
  }

  function $reset() {
    firstName.value = '';
    lastName.value = '';
    email.value = '';
    addressLine1.value = '';
    addressLine2.value = '';
    country.value = '';
    region.value = '';
    city.value = '';
    postalCode.value = '';
    profilePicture.value = '';
    preferredContactMethod.value = '';
    preferredContactInfo.value = '';
    createdAt.value = null;
    updatedAt.value = null;
  }

  return {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    country,
    region,
    city,
    postalCode,
    profilePicture,
    fullName,
    isFullyRegistered,
    email,
    preferredContactMethod,
    preferredContactInfo,
    createdAt,
    updatedAt,
    roles,
    rolesWithAbilities,
    fetchUserInformation,
    $reset,
  };
});
