import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
  faAndroid as fabAndroid,
  faApple as fabApple,
  faDiscord as fabDiscord,
  faFacebook as fabFacebook,
  faGithub as fabGithub,
  faGoogle as fabGoogle,
  faInstagram as fabInstagram,
  faLinkedin as fabLinkedin,
  faSkype as fabSkype,
  faTelegram as fabTelegram,
  faTwitter as fabTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faSort as fadSort, faSortDown as fadSortDown } from '@fortawesome/pro-duotone-svg-icons';
import { faBell as falBell } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowUpArrowDown as farArrowUpArrowDown,
  faBars as farBars,
  faCalendar as farCalendar,
  faChevronRight as farChevronRight,
  faCircleInfo as farCircleInfo,
  faClock as farClock,
  faCode as farCode,
  faCopy as farCopy,
  faEllipsisVertical as farEllipsisVertical,
  faEnvelope as farEnvelope,
  faGrid2 as farGrid2,
  faMagnifyingGlass as farMagnifyingGlass,
  faSidebarFlip as farSidebarFlip,
  faTimes as farTimes,
  faThumbtack as farThumbtack,
  faUsers as farUsers,
  faUserGear as farUserGear,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAddressBook as fasAddressBook,
  faBarsFilter as fasBarsFilter,
  faBellConcierge as fasBellConcierge,
  faBriefcase as fasBriefcase,
  faBrowser as fasBrowser,
  faBuildings as fasBuildings,
  faCamera as fasCamera,
  faChartMixed as fasChartMixed,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronsLeft as fasChevronsLeft,
  faChevronsRight as fasChevronsRight,
  faChevronUp as fasChevronUp,
  faCircleDollar as fasCircleDollar,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasCircleInfo,
  faCircleMinus as fasCircleMinus,
  faCirclePlus as fasCirclePlus,
  faCircleSmall as fasCircleSmall,
  faClock as fasClock,
  faCoin as fasCoin,
  faCoins as fasCoins,
  faEllipsisVertical as fasEllipsisVertical,
  faEnvelope as fasEnvelope,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFaceDisguise as fasFaceDisguise,
  faFileInvoiceDollar as fasFileInvoiceDollar,
  faFileLines as fasFileLines,
  faFileMagnifyingGlass as fasFileMagnifyingGlass,
  faGameConsoleHandheld as fasGameConsoleHandheld,
  faGauge as fasGauge,
  faGear as fasGear,
  faGiftCard as fasGiftCard,
  faGrid2 as fasGrid2,
  faImage as fasImage,
  faLock as fasLock,
  faMoneyBill as fasMoneyBill,
  faMoneyCheckDollar as fasMoneyCheckDollar,
  faObjectsColumn as fasObjectsColumn,
  faPen as fasPen,
  faPenPaintbrush as fasPenPaintbrush,
  faPlus as fasPlus,
  faPresentationScreen as fasPresentationScreen,
  faQuestion as fasQuestion,
  faRadar as fasRadar,
  faRectangleCode as fasRectangleCode,
  faRightToBracket as fasRightToBracket,
  faScreenUsers as fasScreenUsers,
  faSquareCode as fasSquareCode,
  faSquarePollHorizontal as fasSquarePollHorizontal,
  faSquareSliders as fasSquareSliders,
  faStar as fasStar,
  faThumbtack as fasThumbtack,
  faTrashCan as fasTrashCan,
  faTrophy as fasTrophy,
  faUpload as fasUpload,
  faUpRightFromSquare as fasUpRightFromSquare,
  faUser as fasUser,
  faUserGroup as fasUserGroup,
  faUsers as fasUsers,
  faUserUnlock as fasUserUnlock,
  faXmarkLarge as fasXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons';
import { faBuildings as fatBuildings } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

library.add(
  fabAndroid,
  fabApple,
  fabDiscord,
  fabFacebook,
  fabGithub,
  fabGoogle,
  fabInstagram,
  fabLinkedin,
  fabSkype,
  fabTelegram,
  fabTwitter,
  fadSort,
  fadSortDown,
  falBell,
  farArrowUpArrowDown,
  farBars,
  farCalendar,
  farChevronRight,
  farCircleInfo,
  farClock,
  farCode,
  farCopy,
  farEllipsisVertical,
  farEnvelope,
  farGrid2,
  farMagnifyingGlass,
  farSidebarFlip,
  farTimes,
  farThumbtack,
  farUserGear,
  farUsers,
  fasAddressBook,
  fasBarsFilter,
  fasBellConcierge,
  fasBriefcase,
  fasBrowser,
  fasBuildings,
  fasCamera,
  fasChartMixed,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasChevronsLeft,
  fasChevronsRight,
  fasCircleDollar,
  fasCircleExclamation,
  fasCircleInfo,
  fasCircleMinus,
  fasCirclePlus,
  fasCircleSmall,
  fasClock,
  fasCoin,
  fasCoins,
  fasEllipsisVertical,
  fasEnvelope,
  fasEye,
  fasEyeSlash,
  fasFaceDisguise,
  fasFileInvoiceDollar,
  fasFileLines,
  fasFileMagnifyingGlass,
  fasGameConsoleHandheld,
  fasGauge,
  fasGear,
  fasGiftCard,
  fasGrid2,
  fasImage,
  fasLock,
  fasMoneyBill,
  fasMoneyCheckDollar,
  fasObjectsColumn,
  fasPen,
  fasPenPaintbrush,
  fasPlus,
  fasPresentationScreen,
  fasQuestion,
  fasRadar,
  fasRectangleCode,
  fasRightToBracket,
  fasScreenUsers,
  fasSquareCode,
  fasSquarePollHorizontal,
  fasSquareSliders,
  fasStar,
  fasThumbtack,
  fasTrashCan,
  fasTrophy,
  fasUpRightFromSquare,
  fasUpload,
  fasUser,
  fasUserGroup,
  fasUserUnlock,
  fasUsers,
  fasXmarkLarge,
  fatBuildings
);

config.autoAddCss = false;

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp
    .component('fa-icon', FontAwesomeIcon)
    .component('fa-layers', FontAwesomeLayers)
    .component('fa-layers-text', FontAwesomeLayersText);
});
